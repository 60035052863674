import React, { Component } from 'react';

import {
    Button,
    Container,
    Header,
    Responsive,
    Segment,
    Table,
} from 'semantic-ui-react'

class Service extends Component {
    state = {
        toggleButton: false,
    }

    onClick = () => {
        this.setState({
            toggleButton: !this.state.toggleButton

        });
    }

    render() {

        const toggleButton = this.state.toggleButton;
        let display;
        let animateShow;

        if (toggleButton) {
            display =
            <div> 
              <Responsive as={Table} color='blue' inverted striped padded minWidth={761}
              style={{marginTop: '2rem'}}
              >
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Cost</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>Vehicle Inspection</Table.Cell>
        <Table.Cell>$50.00 (Minimum)</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Base Service Check Up</Table.Cell>
        <Table.Cell>$50.00 (Minimum)</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Computer Diagnostic</Table.Cell>
        <Table.Cell>$50.00</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Computer Analysis + Time</Table.Cell>
        <Table.Cell>$100.00</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Safety Inspection + Certificate</Table.Cell>
        <Table.Cell>$250.00</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Mechanical Repair</Table.Cell>
        <Table.Cell>$120.00/Hour</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Electrical Repair</Table.Cell>
        <Table.Cell>$120.00/Hour</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>2 Wheel Alignment</Table.Cell>
        <Table.Cell>$80.00</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>4 Wheel Alignment</Table.Cell>
        <Table.Cell>$120.00</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Storage Fee</Table.Cell>
        <Table.Cell>$80.00/Day</Table.Cell>
      </Table.Row>    
    </Table.Body>
  </Responsive>
  
  <Responsive as={Table} color='blue' inverted striped padded maxWidth={760}
  style={{marginTop: '2rem', fontSize: '1rem'}}
  >
<Table.Header>
<Table.Row>
<Table.HeaderCell>Description</Table.HeaderCell>
<Table.HeaderCell>Cost</Table.HeaderCell>
</Table.Row>
</Table.Header>

<Table.Body>
<Table.Row>
<Table.Cell>Vehicle Inspection</Table.Cell>
<Table.Cell>$50.00 (Minimum)</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>Base Service Check Up</Table.Cell>
<Table.Cell>$50.00 (Minimum)</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>Computer Diagnostic</Table.Cell>
<Table.Cell>$50.00</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>Computer Analysis + Time</Table.Cell>
<Table.Cell>$100.00</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>Safety Inspection + Certificate</Table.Cell>
<Table.Cell>$250.00</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>Mechanical Repair</Table.Cell>
<Table.Cell>$120.00/Hour</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>Electrical Repair</Table.Cell>
<Table.Cell>$120.00/Hour</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>2 Wheel Alignment</Table.Cell>
<Table.Cell>$80.00</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>4 Wheel Alignment</Table.Cell>
<Table.Cell>$120.00</Table.Cell>
</Table.Row>
<Table.Row>
<Table.Cell>Storage Fee</Table.Cell>
<Table.Cell>$80.00/Day</Table.Cell>
</Table.Row>    
</Table.Body>
</Responsive>
</div>
;
animateShow = 
<Button.Content hidden>Hide</Button.Content>;  
        } 
        else {
            animateShow = 
            <Button.Content hidden>View</Button.Content>; 
        }

        return (
          <div>
            <Responsive as={Segment} style={{ padding: '8em 0em' }} vertical minWidth={761}>
                <Container text>
                    <Header as='h1' style={{ fontSize: '2em' }}>
                        Our Services
            </Header>
                    <p style={{ fontSize: '1.33em' }}>
                        TACH Automotive provides a variety of services for you and your family. Our services range from oil changes and tire repairs to brakes
                        and exhaust system services. Please click below to see our service rates.
            </p>
                    <Button as='a' size='large'
                        onClick={this.onClick}
                        animated='fade'
                    >
                    <Button.Content visible>Service Rates</Button.Content>
                        {animateShow}
            </Button>
            <div>
                {display}
            </div>
                </Container>
            </Responsive>

            <Responsive as={Segment} style={{ padding: '4em 0em' }} vertical maxWidth={760}>
                <Container text>
                    <Header as='h3' style={{ fontSize: '1.5rem' }}>
                        Our Services
            </Header>
                    <p style={{ fontSize: '1rem' }}>
                        TACH Automotive provides a variety of services for you and your family. Our services range from oil changes and tire repairs to brakes
                        and exhaust system services. Please click below to see our service rates.
            </p>
                    <Button as='a' size='small'
                        onClick={this.onClick}
                        animated='fade'
                    >
                    <Button.Content visible>Service Rates</Button.Content>
                        {animateShow}
            </Button>
            <div>
                {display}
            </div>
                </Container>
            </Responsive>
            </div>
        )
    }
}

export default Service;
